import React, { useState, useEffect } from 'react';
import {
  IconButton, Popover, Box, Paper, FormHelperText, Tooltip, RadioGroup as MaterialRadioGroup,
  Chip as MuiChip, useMediaQuery, Link as MuiLink, TablePagination, Switch as MaterialSwitch, Slide,
  DialogContent as MuiDialogContent, LinearProgress, RadioGroup, Radio, FormControlLabel, Alert,
  Snackbar, TextField, Autocomplete, InputAdornment, OutlinedInput, Button, Rating, Chip,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import {
  Help as HelpIcon, ContentCopy as ContentCopyIcon, Close as CloseIcon,
  VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon,
  LocalFireDepartment as LocalFireDepartmentIcon, Download as DownloadIcon,
} from '@mui/icons-material/';
import { LoadingButton as MuiButton } from '@mui/lab';
import { useParams, Link as RouteLink } from 'react-router-dom';
import { isValid, format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker, LocalizationProvider, TimePicker as MuiTimePicker } from '@mui/x-date-pickers';
import { DateTimePicker as Picker } from '@mui/x-date-pickers/DateTimePicker';
import compareDateTime from '../../utils/common.helper';

export function ClipboardCopy({ value }) {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const copyToClipboard = (event) => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(value).then(() => {
      setMessage('クリップボードにコピーしました。');
    }).catch(() => {
      setMessage('クリップボードへのコピーに失敗しました。');
    });
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
        setAnchorEl(null);
      }, 1000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [message]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={copyToClipboard} size="small">
        <ContentCopyIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box p={1}>{message}</Box>
      </Popover>
    </>
  );
}

export function RecordBox({ children }) {
  return (<Paper variant="outlined" style={{ padding: '10px', marginBottom: 16, marginTop: '8px' }}>{children}</Paper>);
}

export function withParams(Component) {
  function WithParams(props) {
    return <Component {...props} params={useParams()} />;
  }
  return WithParams;
}

export function FormErrorText({ children }) {
  return (
    <FormHelperText error style={{ fontSize: '0.9rem', margin: 0 }}>{children}</FormHelperText>
  );
}

export function FormTitle({
  title, isRequired = false, attention, isNonFlex,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{ fontWeight: 'bold', flexGrow: (isNonFlex || isMobile) ? null : 1 }}
      display="flex"
    >
      <Box justifyContent="center" display="flex" mb={isNonFlex ? 1 : 0}>
        {title}
        {attention && (
          <Tooltip title={`${attention}が検索できます。複数のワードを検索する場合は、スペース区切りで入力してください。`}>
            <HelpIcon />
          </Tooltip>
        )}
      </Box>
      {isRequired && (
        <MuiChip
          label="必須"
          size="small"
          sx={{
            color: '#fff',
            backgroundColor: '#f50057',
            borderRadius: 0,
            marginLeft: (isNonFlex || isMobile) ? '5px' : 'auto',
          }}
        />
      )}
    </Box>
  );
}

export function Link({
  children, to, target, style,
}) {
  return (
    <MuiLink
      component={RouteLink}
      to={to}
      target={target}
      sx={{
        color: '#1E90FF',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      style={style}
    >
      {children}
    </MuiLink>
  );
}

export function TabPanel(props) {
  const { children, value, index } = props;

  if (value !== index) {
    return null;
  }
  return children;
}

export function TextArea({
  value, onChange, name, isDisabled, error, minRows, maxLength,
}) {
  const onLocalChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  return (
    <Box
      component="textarea"
      disabled={isDisabled}
      name={name}
      value={value || ''}
      onChange={onLocalChange}
      maxLength={maxLength}
      rows={minRows || 5}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontSize: 'inherit',
        fontWeight: 400,
        lineHeight: 1.5,
        padding: '8px 12px',
        borderRadius: '8px',
        color: '#000',
        background: '#fff',
        border: error ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
        '&:hover': {
          borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87)',
        },
        '&:focus': {
          border: error ? '2px solid red' : '2px solid rgb(30,144,255)',
        },
        '&:focus-visible': {
          outline: 0,
        },
      }}
    />
  );
}

export function Image(props) {
  const { mimeType, src, alt } = props;

  return (
    <>
      {(!mimeType || !mimeType.startsWith('video')) && (
        <img src={src} alt={alt} style={{ width: '90%' }} />
      )}
      {mimeType && mimeType.startsWith('video') && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video style={{ width: '90%' }} controls>
          <source
            src={src}
            type={mimeType}
          />
          お使いのブラウザは動画再生をサポートしていません。
        </video>
      )}
    </>
  );
}

export function DialogContent(props) {
  const { children } = props;
  const loading = useSelector((state) => state.commonStore.isLoading);

  return (
    <>
      <LinearProgress color="secondary" style={{ display: loading ? '' : 'none' }} />
      <MuiDialogContent {...props} dividers><Box style={{ display: loading ? 'none' : '' }}>{children}</Box></MuiDialogContent>
    </>
  );
}

export function Pagination(props) {
  const {
    totalElements = 0,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    isShort,
  } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <TablePagination
      rowsPerPageOptions={[50, 100, 150, 200]}
      component="div"
      count={totalElements}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelRowsPerPage={isShort || isMobile ? '表示数：' : '1ページの表示件数：'}
      labelDisplayedRows={({ from, to, count }) => (isShort || isMobile ? `全${count}件` : `全${count}件中 ${from}件から ${to}件までを表示中`)}
    />
  );
}

export function TaxRate({ value, name, onChange }) {
  return (
    <RadioGroup row value={value} name={name} onChange={onChange} defaultValue="10">
      <FormControlLabel value="8" control={<Radio />} label="8%" />
      <FormControlLabel value="10" control={<Radio />} label="10%" />
    </RadioGroup>
  );
}

export function FormRadioGroup(props) {
  const {
    onChange, name, children, value,
  } = props;

  return (
    <MaterialRadioGroup
      {...props}
      onChange={(e) => onChange(
        { target: { name, value: e.target.value ? parseInt(e.target.value, 10) : null } },
      )}
      value={value}
    >
      {children}
    </MaterialRadioGroup>
  );
}

export function FormSwitch(props) {
  const {
    onChange, checked, label, name,
  } = props;

  return (
    <FormControlLabel
      {...props}
      control={(
        <MaterialSwitch
          checked={checked}
          onChange={(e) => onChange({ target: { name, value: e.target.checked } })}
        />
      )}
      label={label}
    />
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export function SuccessSnackbar(props) {
  const { open, onClose, message } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseCheck}
      TransitionComponent={TransitionUp}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={handleCloseCheck}
        severity="success"
        action={(
          <IconButton aria-label="delete" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export function DatePicker(props) {
  const {
    value,
    onChange,
    error,
    maxDate,
    minDate,
    name,
    isDisabled,
  } = props;

  const onLocalChange = (newValue) => {
    let formatValue = newValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd');
    }
    onChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiDatePicker
        openTo="day"
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month', 'day']}
        value={fnsValue}
        onChange={onLocalChange}
        disabled={isDisabled}
        slotProps={{ textField: { variant: 'outlined', error } }}
        format="yyyy/MM/dd"
      />
    </LocalizationProvider>
  );
}

export function DateTimePicker(props) {
  const {
    value,
    onChange,
    name,
  } = props;

  const onLocalChange = (newValue) => {
    let formatValue = newValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd HH:mm:00');
    }
    onChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider
      adapterLocale={ja}
      dateAdapter={AdapterDateFns}
    >
      <Picker
        format="yyyy/MM/dd HH:mm"
        value={fnsValue}
        onChange={onLocalChange}
        slotProps={{ textField: { variant: 'outlined' } }}
      />
    </LocalizationProvider>
  );
}

export function FireIconTitle(props) {
  const {
    isDispText, checkDateTime, isSmall,
  } = props;
  const color = checkDateTime ? compareDateTime(checkDateTime) : null;
  const text = checkDateTime && isValid(new Date(checkDateTime)) ? checkDateTime : '';
  return (
    color && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LocalFireDepartmentIcon style={{ color }} fontSize={isSmall ? 'small' : 'large'} />
        {isDispText && <span style={{ color }}>{text}</span>}
      </div>
    )
  );
}

export function FreeSoloBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  return (
    <Autocomplete
      size="small"
      freeSolo
      options={options.map((option) => option.name)}
      renderInput={(params) => <TextField {...params} name={name} onChange={onChange} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      value={value}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}

export function LoadingButton(props) {
  const { onClick, children } = props;
  const loading = useSelector((state) => state.commonStore.isLoading);

  return (
    <MuiButton onClick={onClick} loading={loading} {...props}>
      {children}
    </MuiButton>
  );
}

export function MonthPicker(props) {
  const {
    value,
    onChange,
    error,
    maxDate,
    minDate,
    name,
  } = props;

  const onLocalChange = (newValue) => {
    let formatValue = newValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd');
    }
    onChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value && isValid(new Date(value)) ? new Date(value.replace(/-/g, '/')) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <DatePicker
        openTo="month"
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month']}
        value={fnsValue}
        onChange={onLocalChange}
        slotProps={{ textField: { variant: 'outlined', error } }}
        inputFormat="YYYY/MM"
      />
    </LocalizationProvider>
  );
}

export function PasswordInput(props) {
  const {
    name, value, onChange, error,
  } = props;
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      fullWidth
      type={showPassword ? 'password' : 'text'}
      value={value}
      name={name}
      error={error}
      inputProps={{ maxLength: 16 }}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      )}
      autoComplete="new-password"
      onChange={onChange}
    />
  );
}

export function PdfDownloadButton(props) {
  const {
    onClick,
    pdfData,
    file,
    isReadyDownload,
    loading,
    onClose,
    title,
  } = props;

  useEffect(() => {
    if (isReadyDownload && pdfData.header.fileName) {
      const generateAndDownloadPDF = async () => {
        const pdfBlob = await pdf(file).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${pdfData.header.fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      };
      generateAndDownloadPDF();
      onClose();
    }
  }, [isReadyDownload, pdfData]);

  return (
    <Button
      variant="contained"
      size="small"
      startIcon={<DownloadIcon />}
      disabled={loading}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export function RatingStar(props) {
  const {
    name,
    value,
    onChange,
    readOnly,
  } = props;

  return (
    <Rating
      name="rate"
      value={Number(value)}
      precision={1}
      onChange={(_, newValue) => { onChange(name, newValue); }}
      size="large"
      readOnly={readOnly}
    />
  );
}

export function TimePicker(props) {
  const {
    value,
    onChange,
    name,
    isDisabled,
    fullWidth,
    error,
    endAdornment,
  } = props;

  const onLocalChange = (changeValue) => {
    let formatValue = !changeValue ? '' : changeValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'HH:mm');
    }
    const event = {
      target: {
        name,
        value: formatValue,
      },
    };

    onChange(event);
  };

  const fnsValue = value ? new Date(`1999/12/31 ${value}`) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiTimePicker
        value={fnsValue}
        disabled={isDisabled}
        sx={fullWidth ? { width: '100%' } : {}}
        onChange={onLocalChange}
        slotProps={{
          textField: {
            variant: 'outlined',
            error,
            InputProps: {
              endAdornment: endAdornment ? (<InputAdornment position="end">{endAdornment}</InputAdornment>) : null,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

export function SearchSelectBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
    minWidth,
  } = props;

  const targetValues = options.filter((row) => row.id === value);
  let [targetValue] = targetValues;
  targetValue = targetValue || null;

  return (
    <Autocomplete
      style={{ minWidth: minWidth || '200px' }}
      size="small"
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      value={targetValue}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue && selectValue.id,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}

export function SearchMultipleSelectBox(props) {
  const {
    options,
    error,
    values,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  const tempValues = [];
  options.forEach((c) => {
    if (values) {
      values.forEach((v) => {
        if (v === c.id) {
          tempValues.push(c);
        }
      });
    }
  });
  return (
    <Autocomplete
      size="small"
      multiple
      fullWidth
      options={options}
      value={tempValues}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      onChange={(_, newValue) => {
        onChange({
          target: {
            name,
            value: newValue.map((n) => n.id),
          },
        });
      }}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip
          label={option.name}
          {...getTagProps({ index })}
        />
      ))}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}
